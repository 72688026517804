<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <add-people :dialog="dialog" @close="dialog.show = false" @save="savingPeople()"></add-people>
    <base-card @addModal="openDialog" :heading="page.title" :dialog="true" :manage="true" :button="button">
      <datatable-list-adm
        :text="text"
        :headers="headers"
        :sortBy="sortBy"
        :items="items"
        :showSelect="showSelect"
        :displayActionItems="displayActionItems"
        :tableUser="true"
        :pagination="pagination"
        :loading="fetch"
        @update="pagination = { ...pagination, ...$event}"
        @getPeople="getPeople"
      ></datatable-list-adm>
    </base-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    id: '',
    filteredPeoples: [],
    page: {
      title: 'Administradores'
    },
    role: 'ADMIN',
    fetch: false,
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Instituição',
        disabled: false,
        to: '/organization',
        exactPath: true
      },
      {
        text: 'Administradores',
        disabled: false,
        to: '/organization/admins'
      }
    ],
    text: {
      searchLabel: 'Pesquisar administradores',
      emptyLabel: 'Nenhum administrador encontrado',
      addLabel: 'Adicionar administradores',
      addUrl: '/school/add',
      importLabel: 'Importar administradores',
      importUrl: '/school/add'
    },
    headers: [
      {
        text: 'Nome',
        align: 'start',
        value: 'name'
      },
      {
        text: 'Permissões',
        align: 'center',
        value: 'roles'
      },
      {
        text: '',
        align: 'center',
        value: 'options',
        sortable: false
      }

    ],
    sortBy: '',
    showSelect: false,
    displayActionItems: false,
    items: [],
    pagination: {
      page: 1,
      itemsPerPage: 100,
      prev_page_url: false,
      next_page_url: false
    },
    button: {
      show: true,
      url: '/organization/people/admin',
      text: 'Administradores',
      icon: 'mdi-plus'
    },
    dialog: {}
  }),
  watch: {
    'pagination.page' (oldValue, newValue) {
      if (oldValue !== newValue) {
        this.getPeople()
      }
    }
  },
  mounted () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')

    this.getPeople()

    this.dialog = {
      show: false,
      title: this.page.title,
      linkSave: `/organizations/${this.id}/people/sync`,
      role: this.role
    }
  },
  methods: {
    openDialog () {
      this.dialog.show = true
    },
    getPeople () {
      const id = localStorage.getItem('ACTIVE_ORGANIZATION')
      if (id) {
        this.fetch = false
        this.$axios.get(`/organizations/${id}/people?roles[]=ADMIN&roles[]=TECHNICAL&roles[]=FINANCIAL&roles[]=ORG_PEDAGOGICAL_COORDINATOR&page=${this.pagination.page}`)
          .then(response => {
            const items = response.data.items

            const arr = []
            if (items.length) {
              items.forEach(value => {
                if (value.data) {
                  const obj = {
                    avatar: value.data.user.avatar,
                    email: value.data.user.email,
                    id: value.data.user.id,
                    name: value.data.user.name,
                    roles: value.data.roles
                  }
                  arr.push(obj)
                }
              })
            }
            this.items = arr

            const pagination = response.data.pagination
            this.pagination = {
              ...this.pagination,
              total: pagination.total,
              page: pagination.current_page,
              itemsPerPage: pagination.per_page
            }
          })
        this.fetch = true
      }
    },
    savingPeople () {
      this.dialog.show = false
      this.items = []
      this.getPeople()
    }
  }
}
</script>
